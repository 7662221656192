<template>
  <div :class="moveParamIsOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="moveParamModal" tabindex="-1" aria-labelledby="moveParamModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="moveParamModalLabel">{{isMoveToLoading ? $t('operations.param_loading_1') : $t('operations.param_firm_1')}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.moveParamIsOpen = false" aria-label="Close"></button>
        </div>
        <div class="position-relative p-3">
          <p>{{isMoveToLoading ? $t('operations.param_loading_2', [unitCount]) : $t('operations.param_firm_2', [unitCount])}}</p>
          <FormItem v-if="isMoveToLoading" id="moveParamtextarea" v-model="moveParam" type="datepicker" :placeholder="defaultValue" />
          <FormItem v-else id="moveParamtextarea" v-model="moveParam" type="radiogroup" :options="standByOptions" :placeholder="defaultValue" />
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" @click.prevent="setMoveParam" :disabled="!moveParam">{{$t('form.save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['moveParamIsOpen', 'unitCount'],
        name: 'VoyagePlanningMoveParamPopup',
        data() {
            return {
                moveParam: '',
                defaultValue: null,
                isMoveToLoading: false,
                standByOptions: [{id: 2, name: 'Set to Stand-by'}, {id: 1, name: 'Move to permis normally'}],
                port: null
            }
           
        },
        methods: {
          setMoveParam(){
            console.log(this.port);
            this.$parent.extraParameter = this.moveParam;
            this.$parent.bulkActionUnits(this.port, 'status_id', 1);
            this.$parent.moveParamIsOpen = false;
          }
        }
    }
</script>