<template>
    <div>
        <Overview 
            :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
            :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
            :sort="'created_at'"
            :itemNameField="'number'"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :sortOrder="2"
            modalWidth="1400px"
            :showAddButton="false"
            :hideFooter="true"
        >   
        <template #columns>
            <Column field="vessel.name" :header="$tc('navigation.vessels', 1)" style="width: 25%"></Column>
            <Column field="number" :header="$t('navigation.voyages')" style="width: 120px"></Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers', 1)" style="width: 20%"></Column>
            <Column field="voyage_status_id" :header="`Booking status`" style="width: 120px">
                <template #body="slotProps"> 
                    <span class="badge" :data-status="$t('voyage_status.'+slotProps.data.voyage_status_id).toLowerCase()">
                        {{slotProps.data.voyage_status_id ? $t('voyage_status.'+slotProps.data.voyage_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="amount_waiting" class="center-td" :header="$t('overview.vp_waiting')" style="width: 80px"></Column>
            <Column field="amount_firm" class="center-td" :header="$t('overview.vp_firm')" style="width: 80px"></Column>
            <Column field="amount_permis" class="center-td" :header="$t('overview.vp_permis')" style="width: 80px"></Column>
            <Column field="amount_loaded" class="center-td" :header="$t('operations.date_loaded')" style="width: 80px"></Column>
            <Column field="created_at" :header="`Created`" style="width: 120px">
                <template #body="slotProps"> {{formatDate(slotProps.data.created_at, 'shortDate')}} </template>
            </Column>
        </template>

        <template #form>
            <VoyagePlanningForm ref="ItemForm" :itemData="item" />
        </template>
        </Overview>
        <div class="d-none" @click="updateAllCounts">_</div>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';

import voyageService from '@/services/VoyagePlanningService';
import Column from '@/components/column';
import VoyagePlanningForm from '@/components/voyage/VoyagePlanningForm.vue';

export default {
    components: {
        Overview,
        Column,
        VoyagePlanningForm
    },
    data() {
        return {
            item: {},
            itemService: voyageService,
            itemFormRef: null
        }
    },
    methods: {
        updateAllCounts(){
            voyageService.updateAllCounts();
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>