<template>
  <div :class="remarkIsOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="remarkModal" tabindex="-1" aria-labelledby="remarkModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="remarkModalLabel">{{$t('form.add', [$t('form.remark').toLowerCase()])}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.addRemarkOpen(null, false)" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <FormItem id="remarktextarea" type="textarea" v-model="remark" />
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" @click.prevent="$parent.bulkActionUnits(port, 'remark', remark)" :disabled="!remark">{{$t('form.save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['remarkIsOpen'],
        name: 'VoyagePlanningRemarkPopup',
        data() {
            return {
                remark: '',
                port: null
            }
           
        },
        methods: {
            //
        }
    }
</script>