<template>
  <div :class="allocIsOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="allocModal" tabindex="-1" aria-labelledby="allocModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="allocModalLabel">{{$t('operations.allocation')}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.allocOpen(false)" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <FormItem id="bgq-1" :label="$t('operations.alloc_set_type')" type="radiogroup" v-model="$parent.perCommodity" :disabled="!canEdit"
                   :options="[{id: true, name: $t('operations.per_group')},{id: false, name: $t('operations.per_voyage')}]" @onchange="$parent.setCompany(false, true)" />
          <FormItem id="bgq-2" :label="$t('operations.alloc_set_office')" type="radiogroup" v-model="$parent.perCompany" :disabled="!canEdit"
                   :options="[{id: true, name: $t('form.yes')},{id: false, name: $t('form.no')}]" @onchange="$parent.setCompany(true, true)" />
          <FormItem :label="$t('crm.company')" v-if="perCompany" v-model="defaultOffice" :options="offices" type="select" :disabled="!canEdit"
                    :disabledOptions="$parent.checkIfAlreadyChosen" @onchange="$parent.addCompany" />
          <br />
          <template v-if="perCompany">
            <ul class="nav nav-tabs mb-3  header-part-2" id="myTab" role="tablist">
              <li class="nav-item" role="presentation" v-for="comp, cindex in selectedCompanies" :key="comp.id">
                <button class="nav-link" :id="'ctab-'+comp.id" data-bs-toggle="tab" :data-bs-target="'#comp-'+comp.id" type="button" role="tab" :aria-controls="'comp-'+comp.id" aria-selected="false">
                  {{ comp.name }} &ensp; <span class="badge bg-light" v-show="cindex > 0">
                    <i class="fal text-danger fa-trash-alt" v-show="canEdit" @click="$parent.removeCompany(cindex, comp)"></i>
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContentAlloc">
              <div v-for="comp, compindex in selectedCompanies" :key="comp.id" class="tab-pane fade" :id="'comp-'+comp.id" role="tabpanel" :aria-labelledby="'ctab-'+comp.id">
                <template v-if="perCommodity">
                  <div class="row mb-1" v-for="group in commgroups" :key="group.id">
                    <div class="col-3"> {{ group.name }} </div>
                    <div class="col-3" v-if="group.companies[compindex]">
                      <FormItem type="number" v-model="group.companies[compindex].value" :disabled="!canEdit" :required="true" :id="'tfield-'+group.id+'-'+comp.id" />
                    </div>
                    <div class="col-2" v-if="group.companies[compindex]">
                      <FormItem v-model="group.companies[compindex].mode" :options="modes" type="select" :selectDefault="false" :disabled="!canEdit" />
                    </div>
                  </div>
                </template>
                <div class="row" v-else>
                  <div class="col-3"> {{ $t('navigation.voyages') }} </div>
                  <div class="col-3">
                    <FormItem type="number" v-model="comp.value" :disabled="!canEdit" :required="true" :id="'tfield-norm-'+comp.id" />
                  </div>
                  <div class="col-2">
                    <FormItem v-model="comp.mode" :options="modes" type="select" :selectDefault="false" :disabled="!canEdit" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="perCommodity">
            <div class="row mb-1" v-for="group in commgroups" :key="group.id">
              <div class="col-3"> {{ group.name }} </div>
              <div class="col-3">
                <FormItem type="number" v-model="group.max_space" :disabled="!canEdit" :required="true" />
              </div>
              <div class="col-2">
                <FormItem v-model="group.mode" :options="modes" type="select" :selectDefault="false" :disabled="!canEdit" />
              </div>
            </div>
          </template>
          <div class="row" v-else>
            <div class="col-3"> {{ $t('navigation.voyages') }} </div>
            <div class="col-3">
              <FormItem type="number" v-model="$parent.totalAllocSpace" :disabled="!canEdit" :required="true" />
            </div>
            <div class="col-2">
              <FormItem v-model="$parent.totalAllocMode" :options="modes" type="select" :selectDefault="false" :disabled="!canEdit" />
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" v-show="canEdit" @click.prevent="saveAlloc">{{$t('form.save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['totalMode', 'allocIsOpen', 'totalSpace', 'perCommodity', 'perCompany', 'selectedCompanies', 'offices', 'commgroups', 'canEdit'],
        name: 'VoyagePlanningAllocationPopup',
        data() {
            return {
                modes: [{id: 1, name: 'm²'},{id: 2, name: 'units'},{id: 3, name: 'cbm'}],
                defaultOffice: null
            }
        },
        methods: {
            saveAlloc() {
                let valid = true;
                let firstTabId = 0;
                let required = document.querySelectorAll('#allocModal input.number-format');
                console.log(required);
                for(let field of required) {
                    if(!field.value){
                        valid = false;
                        field.classList.add("is-invalid");
                        if(!firstTabId) firstTabId = field.id ? field.id.split('-').at(-1) : 0;
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                if(!valid){
                    alert(this.$t('errors.empty_fields'));
                    if(this.perCompany){
                        document.querySelector('#ctab-'+firstTabId).click();
                    }
                    return;
                }
                this.$parent.saveAlloc();
            },
        }
    }
</script>