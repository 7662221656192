<template>
  <div :class="closingIsOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="closingModal" tabindex="-1" aria-labelledby="closingModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="closingModalLabel">{{$t('operations.open_close')}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.closingOpen(false)" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6 class="fw-bold">{{$t('operations.combination_new')}}</h6>
          <div class="row mb-1">
            <div class="col-3">
              <label class="col-form-label-sm">{{ $t('sales.loading_ports') }}</label>
              <FormItem v-model="closingData.port_of_loading_id" :options="initialVoyage.port_voyages" type="select" :placeholder="$t('form.all')" @onchange="$parent.setPolCode" :disabled="!canEdit" />
            </div>
            <div class="col-3">
              <label class="col-form-label-sm">{{ $t('sales.discharge_ports') }}</label>
              <FormItem v-if="closingData.port_of_loading_id && closingData.port_of_loading_code && initialVoyage.ports[closingData.port_of_loading_code] && initialVoyage.ports[closingData.port_of_loading_code].pods" v-model="closingData.port_of_discharge_id" :options="initialVoyage.ports[closingData.port_of_loading_code].pods" type="select" :placeholder="$t('form.all')" />
              <FormItem v-else v-model="closingData.port_of_discharge_id" :options="initialVoyage.port_voyages" type="select" :placeholder="$t('form.all')" :disabled="!canEdit" />
            </div>
            <div class="col-3">
              <label class="col-form-label-sm">{{ $t('navigation.commoditygroups') }}</label>
              <FormItem v-model="closingData.commodity_group_id" :options="commgroups" type="select" :disabled="!canEdit" :placeholder="$t('form.all')" />
            </div>
            <div class="col-3">
              <label class="col-form-label-sm">{{ $t('overview.state') }}</label>
              <FormItem v-model="closingData.status" :options="states" type="select" :disabled="!canEdit" :selectDefault="false" />
            </div>
          </div>
          <div class="d-flex justify-content-end mb-3">
            <button type="button" class="btn btn-success" v-show="canEdit" @click.prevent="$parent.createClosing">{{$t('form.save')}}</button>
          </div>

          <h6 class="fw-bold"><i class="clickable far fa-lock"></i>&ensp;{{$t('operations.combination_closed')}}</h6>
          <div class="row mb-1">
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('sales.loading_ports') }}</label>
            </div>
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('sales.discharge_ports') }}</label>
            </div>
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('navigation.commoditygroups') }}</label>
            </div>
            <div class="col-3"></div>
          </div>
          <template v-for="alloc, aindex in initialVoyage.voyage_port_commodity_statuses" :key="aindex">
            <div class="row mb-1" v-if="alloc.is_closed == 1">
              <div class="col-3"> {{ alloc.port_of_loading_id ? safeFind(ports, 'id', alloc.port_of_loading_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"> {{ alloc.port_of_discharge_id ? safeFind(ports, 'id', alloc.port_of_discharge_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"> {{ alloc.commodity_id ? safeFind(commgroups, 'id', alloc.commodity_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"><i class="fal clickable text-danger fa-trash-alt" @click="$parent.removeClosing(alloc)"></i></div>
            </div>
          </template>

          <!--<h6 class="fw-bold mt-3"><i class="clickable far fa-lock-open"></i>&ensp;{{$t('operations.combination_open')}}</h6>
          <div class="row mb-1">
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('sales.loading_ports') }}</label>
            </div>
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('sales.discharge_ports') }}</label>
            </div>
            <div class="col-3">
              <label class="fw-bold col-form-label-sm">{{ $t('navigation.commoditygroups') }}</label>
            </div>
            <div class="col-3"></div>
          </div>
          <template v-for="alloc, aindex in initialVoyage.voyage_port_commodity_statuses" :key="aindex">
            <div class="row mb-1" v-if="alloc.status == 'open' || alloc.status == 1">
              <div class="col-3"> {{ alloc.port_of_loading_id ? safeFind(ports, 'id', alloc.port_of_loading_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"> {{ alloc.port_of_discharge_id ? safeFind(ports, 'id', alloc.port_of_discharge_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"> {{ alloc.commodity_id ? safeFind(commgroups, 'id', alloc.commodity_id, 'name') : $t('form.all') }} </div>
              <div class="col-3"><i class="fal clickable text-danger fa-trash-alt" @click="$parent.removeClosing(alloc)"></i></div>
            </div>
          </template>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['initialVoyage', 'closingDat', 'closingIsOpen', 'ports', 'commgroups', 'canEdit'],
        name: 'VoyagePlanningClosingPopup',
        computed: {
            thisvoyageport: {
                get() {
                    return this.initialVoyage
                }
            },
            closingData: {
                get() {
                    return this.closingDat
                }
            }
        },
        data() {
            return {
                defaultOffice: null,
                states: [{id: 1, name: 'Open'},{id: 2, name: 'Closed'}],
            }
           
        },
        methods: {
            //
        }
    }
</script>